import { useWeb3React } from '@telekomconsalting/core'
import classNames from 'classnames'
import React from 'react'

import { TokenV3 } from '../model'
import { setQuoteToken } from '../services/tokenService'
import {
  getInlineNetworkIconStyle,
  getMarketDisplayName,
  getNetworkConfig,
  getNetworkConfigByChainId,
} from '../utils'
import IconTokenWrapper from './IconTokenWrapper'

interface TokensFormRowProps {
  token: TokenV3
  setIsShowDown: (value: boolean) => void
  onChangeToken?: (token?: TokenV3) => void
  isChangeQuoteToken?: boolean
}

function TokensFormRow(props: TokensFormRowProps): React.ReactElement {
  const token = props.token
  const { account, library, chainId } = useWeb3React()

  const network = getNetworkConfigByChainId(chainId)?.name

  const selectToken = (): void => {
    if (props.onChangeToken) {
      props.onChangeToken(token)
    }
    if (props.isChangeQuoteToken) {
      setQuoteToken(token, library, account, network)
    }
    props.setIsShowDown(false)
  }

  const networkConfig = getNetworkConfig(token.network)
  const styleIcon = getInlineNetworkIconStyle(token?.network, networkConfig)

  return (
    <li className="tradeform-tokens__item" onClick={selectToken}>
      <div className="tradeform-token">
        <div
          className={classNames(
            'tradeform-token__icon',
            'token-ico',
            'token-ico--sm',
            token.network ? `token-ico--network-${token.network}` : ''
          )}
          style={styleIcon}>
          <IconTokenWrapper
            logoURI={token.logoURI}
            symbols={token.symbols}
            className="token-ico__image"
          />
        </div>
        <span className="tradeform-token__name" title={getMarketDisplayName(token)}>
          <span className="caption">{getMarketDisplayName(token)}</span>
        </span>
      </div>
    </li>
  )
}

export default TokensFormRow
