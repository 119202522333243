import React, { FC } from 'react'

import { TrackInfoData } from '../model'

export const TooltipAnchor: FC<{
  tooltip: string
  tooltipClassName?: string
  place?: 'top' | 'right' | 'bottom' | 'left'
  type?: 'light' | 'dark'
  tooltipId?: string
  trackInfo?: TrackInfoData
}> = ({ tooltip, tooltipClassName, place, type, trackInfo }) => {
  const data = trackInfo ? JSON.stringify(trackInfo) : undefined

  return (
    <span
      className="tooltip-anchor"
      data-class={tooltipClassName}
      data-tip={tooltip}
      data-for="app-tooltip"
      data-place={place}
      data-type={type}
      data-track-info={data}>
      <span className="caption">Details</span>
    </span>
  )
}
