import BigNumber from 'bignumber.js'
import React, { FC } from 'react'

import { TokenV3 } from '../../model'
import { tipTooltipText } from '../TxnModals/resources'
import TipValue from './TipValue'

const TipSummary: FC<{ tip: number; tokenTo: TokenV3; toAmount: BigNumber }> = ({
  tip,
  toAmount,
  tokenTo,
}) => (
  <>
    <div className="swap-verify-data__header">
      <div className="row row--no-gap row--align-center">
        <span className="label">Tip for your Guru:</span>
        <span className="tooltip-anchor" data-for="app-tooltip" data-tip={tipTooltipText} />
      </div>
    </div>
    <div className="swap-verify-data__body">
      {tip > 0 && <TipValue tip={tip} toAmount={toAmount} tokenTo={tokenTo} />}
      {!tip && (
        <div className="swap-verify-data__tips-summary">{tip || 'None... maybe next time!'}</div>
      )}
    </div>
  </>
)

export default TipSummary
