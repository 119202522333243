import React from 'react'

import { TradeBarData } from '../model'
import { pickValueBasedOnCurrency } from '../utils'
import MeterBar from './MeterBar'

export interface TokenValueBarProps {
  shouldDisplay: boolean
  tradeBarData: TradeBarData
  tradeAmount: number
  selectedCurrency: string
  isPositive?: boolean
}

function TokenValueBar(props: TokenValueBarProps): React.ReactElement {
  const { max } = props.tradeBarData

  const maxAmount = pickValueBasedOnCurrency(props.selectedCurrency, max.usd, max.eth)

  const objValue = {
    positive: props.isPositive ? props.tradeAmount : undefined,
    negative: !props.isPositive ? props.tradeAmount : undefined,
  }

  return (
    <>
      {props.shouldDisplay && (
        <MeterBar
          className="meter-bar--sm meter-bar--clear"
          value={objValue}
          max={maxAmount}
          only={props.isPositive ? 'positive' : 'negative'}
        />
      )}
    </>
  )
}

export default TokenValueBar
