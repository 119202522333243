import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import React, { FC } from 'react'

import { financialFormat } from '../../helpers/priceAndAmountHelpers'
import { TokenWithApprovalContext } from '../../model'
import { getMarketDisplayName } from '../../utils'

const TipValue: FC<{
  tip: number
  tokenTo: TokenWithApprovalContext
  toAmount: BigNumber
  readonly?: boolean
}> = ({ tokenTo, tip, toAmount }) => {
  const tipValue = toAmount.div(100).times(tip)
  const feeSign = getMarketDisplayName(tokenTo)
  const tipValueUsd = tipValue.times(tokenTo.priceUSD)

  return (
    <div className={classNames('swap-verify__tips-value', { active: !!tip })}>
      <div className="swap-verify__token-amount">
        <div className="swap-verify__col">
          <div className="swap-verify__nativefee">
            <span className="value">{financialFormat(tipValue)}</span>
            &nbsp;
            <span className="sign">{feeSign}</span>
          </div>
          <div className="swap-verify__usd">
            <span className="sign">~$</span>
            <span className="value">{financialFormat(tipValueUsd)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TipValue
