import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setPools } from '../actions'
import { PoolsOptions } from '../model'
import { State } from '../reducers'
import { getPools } from '../services/poolsService'
import { pickValueBasedOnCurrency } from '../utils'
import ChartTreeMap from './ChartTreeMap'
import { Heading } from './Heading'
import { ClickPoolAddressHandler } from './PoolActivity/useTradeHistoryFilter'
import { SidebarType } from './Sidebar/sidebarUtils'

const TopPools: FC<{ onClickPoolAddress: ClickPoolAddressHandler }> = (props) => {
  const reduxDispatch = useDispatch()
  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const poolsState = useSelector((state: State) => state.pools)
  const currency = useSelector((state: State) => state.currency)
  const sidebarOpen = useSelector((state: State) => state.ui.isSidebarOpen)

  const sortBy =
    process.env.REACT_APP_POOLS_V3 === 'true'
      ? sidebarOpen === 'history'
        ? currency === 'USD'
          ? 'volume24h_stable'
          : 'volume24h_native'
        : currency === 'USD'
        ? 'liquidity_stable'
        : 'liquidity_native'
      : sidebarOpen === 'history'
      ? currency === 'USD'
        ? 'volume24hUSD'
        : 'volume24hETH'
      : currency === 'USD'
      ? 'liquidityUSD'
      : 'liquidityETH'

  const getPoolsData = React.useCallback(async (): Promise<void> => {
    const options: PoolsOptions = {
      network: currentToken?.network,
      sort_by: sortBy,
      id: currentToken?.id,
    }
    const poolsData = await getPools(options)
    if (poolsData) {
      reduxDispatch(setPools(poolsData))
    }
  }, [currentToken?.id, currentToken?.network, sortBy, reduxDispatch])

  useEffect(() => {
    // not inited
    if (sidebarOpen === SidebarType.none) {
      return
    }
    getPoolsData()
  }, [currentToken?.id, sidebarOpen, getPoolsData])

  const title = sidebarOpen === 'history' ? 'Top Trading Pools' : 'Top Liquidity Pools'
  const tooltip =
    sidebarOpen === 'history'
      ? 'Top Trading Pools have the highest trading volume for the token. The values displayed in each pool are its trading volume in past 24 hours.'
      : 'TVL aka Total Value Locked. TVL is the dollar value locked inside liquidity pools. Top Liquidity Pools displayed have the highest TVL for the token.'

  let poolsDataMax = 0
  poolsState.forEach((item) => {
    poolsDataMax +=
      sidebarOpen === 'history'
        ? pickValueBasedOnCurrency(currency, item?.volume24hStable, item?.volume24hNative)
        : pickValueBasedOnCurrency(currency, item?.liquidityStable, item?.liquidityNative)
  })

  const extraSmallLimit = 0.01 // 1%

  const poolsData = poolsState.filter((item) => {
    const value =
      sidebarOpen === 'history'
        ? pickValueBasedOnCurrency(currency, item?.volume24hStable, item?.volume24hNative)
        : pickValueBasedOnCurrency(currency, item?.liquidityStable, item?.liquidityNative)
    return value > 0 && value / poolsDataMax > extraSmallLimit
  })

  if (poolsData.length === 0) {
    return null
  }

  return (
    <div className="activity-overview-chart">
      <div className="activity-overview-chart__header">
        <Heading size="md" tooltip={tooltip}>
          {title}
        </Heading>
      </div>

      <div className="activity-overview-chart__body">
        <ChartTreeMap data={poolsData} onClickPoolAddress={props.onClickPoolAddress} />
      </div>
    </div>
  )
}

export default TopPools
