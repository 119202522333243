import {
  isTransactionTypesValid,
  isValidAddress,
  isValidAmm,
  isValidExchangeTokenTrade,
  isValidNetwork,
  isValidTokenStatus,
  isValidWalletCategory,
} from '../../helpers/validators'
import { FilterOptions, TokenStatuses } from '../../model'
import { FilterOptionsWithStrValues } from './tokenProfileReducer'

const stringifyValues = (filterValues: FilterOptions): FilterOptionsWithStrValues => {
  const newValue: Partial<FilterOptionsWithStrValues> = {}

  if (isValidAmm(filterValues.amm) && filterValues.amm !== 'all') {
    newValue.amm = filterValues.amm as string
  }

  if (isValidNetwork(filterValues.network)) {
    newValue.network = filterValues.network as string
  }

  if (filterValues.account && isValidAddress(filterValues.account)) {
    newValue.account = filterValues.account
  }

  if (filterValues.second_account && isValidAddress(filterValues.second_account)) {
    newValue.second_account = filterValues.second_account
  }

  if (isValidAddress(filterValues.pool_address)) {
    newValue.pool_address = filterValues.pool_address as string
  }

  if (
    isValidTokenStatus(filterValues.token_status) &&
    filterValues.token_status !== TokenStatuses.all
  ) {
    newValue.token_status = filterValues.token_status as TokenStatuses
  }

  if (filterValues.date && Object.keys(filterValues.date).length) {
    if (filterValues.date.period) {
      newValue.date_period = String(filterValues.date.period)
    }
    if (filterValues.date.start_date) {
      newValue.date_start_date = String(filterValues.date.start_date)
    }
    if (filterValues.date.end_date) {
      newValue.date_end_date = String(filterValues.date.end_date)
    }
  }

  if (
    filterValues.trade_size_usd &&
    Object.keys(filterValues.trade_size_usd).length &&
    !!filterValues.trade_size_usd.amount
  ) {
    newValue.trade_size_usd_operator = filterValues.trade_size_usd?.operator
    newValue.trade_size_usd_amount = String(filterValues.trade_size_usd?.amount)
  }

  if (isValidWalletCategory(filterValues.wallet_category)) {
    newValue.wallet_category = encodeURIComponent(JSON.stringify(filterValues.wallet_category))
  }

  if (filterValues.transaction_types && isTransactionTypesValid(filterValues.transaction_types)) {
    newValue.transaction_types = filterValues.transaction_types[0]
  }

  if (filterValues.token_id && isValidExchangeTokenTrade(filterValues.token_id)) {
    newValue.token_id = filterValues.token_id
  }
  return newValue
}

export default stringifyValues
