import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { useComponentDidMount } from '../hooks/useComponentDidMount'
import { TokenV3 } from '../model'
import { State } from '../reducers'
import { searchTokensBySymbol } from '../services/tokenService'
import TokensFormRow from './TokensFormRow'

interface TokensFormListProps {
  searchSymbol: string
  isShowDown: boolean
  setIsShowDown: (value: boolean) => void
  onChangeToken?: (token: TokenV3 | undefined) => void
  isShowCurrenntToken?: boolean
  network?: string
  isChangeQuoteToken?: boolean
}
export default function TokensFormList(props: TokensFormListProps): React.ReactElement {
  const activeToken = useSelector((state: State) => state.tokens.currentToken)
  const [tokens, setTokens] = useState<TokenV3[]>([])

  const resultsRef = useRef<HTMLDivElement>(null)

  const obtainTokens = React.useCallback(async (): Promise<void> => {
    if (props.searchSymbol.length >= 2) {
      await searchTokensBySymbol(props.searchSymbol, props.network || 'all').then((tokens) =>
        setTokens(tokens)
      )
    }
  }, [props.searchSymbol, props.network])

  useEffect(() => {
    obtainTokens()
  }, [obtainTokens])

  const renderTokens = (tokens: TokenV3[]): React.ReactNode | null => {
    if (tokens && tokens.length > 0) {
      return tokens.map((token) =>
        props.isShowCurrenntToken || token.id !== activeToken?.id ? (
          <TokensFormRow
            token={token}
            key={token.id}
            setIsShowDown={props.setIsShowDown}
            onChangeToken={props.onChangeToken}
            isChangeQuoteToken={props.isChangeQuoteToken}
          />
        ) : null
      )
    } else {
      return null
    }
  }

  useComponentDidMount(() => {
    const handleKeys = (event: KeyboardEvent): void => {
      if (!props.isShowDown) {
        return
      }

      let currentRow: HTMLElement | null | undefined = resultsRef.current?.querySelector(
        '.tradeform-tokens__item.is--selected'
      )
      if (event.code === 'ArrowUp' || event.code === 'ArrowDown') {
        // pressed: ⬆ ⬇

        const isPrev = event.code === 'ArrowUp'

        if (!currentRow) {
          currentRow = resultsRef.current?.querySelector(
            '.tradeform-combo--open .tradeform-tokens__item:first-child'
          )
          currentRow?.classList.add('is--selected')
          currentRow?.scrollIntoView({ behavior: 'smooth', block: 'start' })
          return
        }

        const otherRow = currentRow[isPrev ? 'previousElementSibling' : 'nextElementSibling']

        if (otherRow) {
          currentRow.classList.remove('is--selected')
          otherRow?.classList.add('is--selected')
          otherRow?.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
      }

      if (event.code === 'Enter') {
        currentRow && currentRow.click()
      }
    }

    window.addEventListener('keydown', handleKeys)

    return (): void => {
      window.removeEventListener('keydown', handleKeys)
    }
  })

  return (
    <React.Fragment>
      {tokens && tokens.length > 0 ? (
        <div className="tradeform-tokens__results" ref={resultsRef}>
          <ul className="tradeform-tokens__list">{renderTokens(tokens)}</ul>
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}
