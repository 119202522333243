import { model, modelUtils } from '@telekomconsalting/dex-guru-model'
import classNames from 'classnames'
import React, { FC } from 'react'

import { getAmmConfig } from '../config/amm'
import { buildTokenRoute } from '../routes'
import {
  getInlineNetworkIconStyle,
  getMarketDisplayNameBySymbols,
  getNetworkConfig,
} from '../utils'
import IconTokenComponent from './IconTokenComponent'

interface TokenProps {
  id?: string
  logoURI?: string[]
  symbols?: string[]
  network?: string
  AMM?: model.AMM
  marketType?: string
}

interface TokenAssetProps {
  token: TokenProps
  isLink?: boolean
  onlyIcon?: boolean
  size?: 'lg' | 'md' | 'sm' | 'xs'
  className?: string
}

const TokenAsset: FC<TokenAssetProps> = ({
  token,
  onlyIcon,
  size = 'md',
  isLink = false,
  className,
}) => {
  const { logoURI = [], symbols = [], network, AMM: amm, id, marketType } = token
  let styleIcon
  if (network) {
    const networkConfig = getNetworkConfig(network)
    styleIcon = getInlineNetworkIconStyle(network, networkConfig)
  }

  const symbolsContent = getMarketDisplayNameBySymbols({ symbols })
  const ammContent = amm ? getAmmConfig(amm).displayName : undefined

  const renderBody = (): React.ReactElement => {
    return (
      <>
        <div className="token-asset__icon">
          {symbols.map((symbol, idx) => {
            return (
              <div className={`token-ico token-ico--${size}`} key={`${symbol}-${network}-${idx}`}>
                <IconTokenComponent
                  iconToken={logoURI[idx]}
                  symbol={symbol}
                  className="token-ico__image"
                  width="40"
                  height="40"
                />
              </div>
            )
          })}
        </div>
        {!onlyIcon && (
          <div className="token-asset__name">
            <span className="token-asset__content">
              <span className="token-asset__symbol">
                <span className="caption">{symbolsContent}</span>
              </span>
              {amm && marketType === 'lp' && (
                <>
                  &nbsp;
                  <span className="token-asset__amm">
                    <span className="caption">{ammContent}</span>
                  </span>
                  &nbsp;
                  <span className="token-asset__mark">
                    <span className="caption">LP</span>
                  </span>
                </>
              )}
              {network && network !== 'eth' && (
                <span className="token-asset__network">
                  &nbsp;<span className="caption">{modelUtils.normalizeNetworkName(network)}</span>
                </span>
              )}
            </span>
          </div>
        )}
      </>
    )
  }

  const resultClassName = classNames('token-asset', `token-asset--${size}`, {
    [`${className}`]: !!className,
  })

  return isLink && id ? (
    <a
      href={buildTokenRoute(id)}
      target="_blank"
      rel="noreferrer noopener"
      className={resultClassName}
      style={styleIcon}>
      {renderBody()}
    </a>
  ) : (
    <div className={resultClassName} style={styleIcon}>
      {renderBody()}
    </div>
  )
}

export default TokenAsset
